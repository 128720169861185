<template>
  <div>
    <title>SIM INVENTORY ~ DATA RECEIVE OF ITEMS</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Data Receive of Items
        <br />
        <h4>The following is a list of items receive transaction data lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-xs-12">
          <router-link to="/add-penerimaan">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Add Data </i>
            </button>
          </router-link>
          <br /><br />
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Data Receive of Items</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-3">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Receive Number"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-1">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Receive Number</th>
                      <th>BL Number</th>
                      <th>Receive Date</th>
                      <th>Register Number</th>
                      <th>Register Date</th>
                      <th>Supplier Name</th>
                      <th>Notes</th>
                      <th>Action</th>
                      <!-- <th>Detail</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.idx }}</td> -->
                      <td>{{ classdata.receipt_number }}</td>
                      <td>{{ classdata.bl_number }}</td>
                      <td>{{ classdata.receipt_date }}</td>
                      <td>{{ classdata.bc_no }}</td>
                      <td>{{ classdata.bc_date }}</td>
                      <td>{{ classdata.nama_supplier }}</td>
                      <td>{{ classdata.notes }}</td>

                      <td>
                        <router-link :to="'/edit-penerimaan/' + classdata.id">
                          <button
                            title="Edit Items Receive"
                            class="btn btn-info"
                          >
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>
                        <br />
                        <p></p>

                        <router-link
                          :to="
                            '/list-penerimaandetail/' + classdata.receipt_number
                          "
                        >
                          <button
                            title="View Detail Items Receive"
                            class="btn btn-success"
                          >
                            <i class="fa fa-list"></i>
                          </button>
                        </router-link>

                        <br />
                        <p></p>

                        <button
                          title="Delete Items Receive"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>

                      <!-- <td>
                        <router-link
                          :to="
                            '/add-penerimaandetail/' +
                              classdata.auto_tpb +
                              '/' +
                              classdata.bc_no
                          "
                        >
                          <button
                            title="Add Detail Items Receive"
                            class="btn btn-info"
                          >
                            <i class="fa fa-plus"></i>
                          </button>
                        </router-link>
                       <br> <p></p>
                        
                      </td> -->
                    </tr>
                  </tbody>
                </table>

                <div>
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination
  },
  data() {
    return {
      isShow: false,
      nmsrc: true,
      loading: false,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/penerimaan_h/getalldatapenerimaan_h?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "penerimaan_h/getalldatapenerimaan_h?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.list_paged = resp.data.data;
          // sessionStorage.setItem('bcno',this.$route.params.nobc)
          this.count = resp.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      const params = this.getRequestParams(this.page);
      //const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/penerimaan_h/getalldatapenerimaan_h?cari=" + this.namesearch+"&length="+this.pageSize;
      const urlAPIget =
        this.$apiurl +
        "penerimaan_h/getalldatapenerimaan_h?cari=" +
        this.namesearch +
        "&length=" +
        this.pageSize;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          this.list_paged = resp.data.data;
          this.count = resp.data.datatotalcount;
          this.loading = false;
          this.nmsrc = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/hapusproduk_h/" + id;
          const urlAPIbrgdel =
            this.$apiurl + "penerimaan_h/hapuspenerimaan_h/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              })
            });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
